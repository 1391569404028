import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Heading from '../components/Heading'
import Button from '../components/Button'
import { createClient } from '@supabase/supabase-js'
import { useHistory } from 'react-router-dom'
const supabaseUrl = 'https://rgyzwbtbfegtsrqtgwzd.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJneXp3YnRiZmVndHNycXRnd3pkIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY3NzUxNTg0MSwiZXhwIjoxOTkzMDkxODQxfQ.Lqn5d5lvq_1w01hBKy4Gt_TMYFF4VwTp7o2mDcUbutc'
const supabase = createClient(supabaseUrl, supabaseKey)

function SignUp() {
    const history = useHistory()
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [fullName, setFullName] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState('')

    const emailChange = (e) => {
        setEmail(e.target.value)
    }
    const passwordChange = (e) => {
        setPassword(e.target.value)
    }
    const fullNameChange = (e) => {
        setFullName(e.target.value)
    }

    const [pricing, setPricing] = useState([
        {
            title: 'Basic',
            price: '$5/mon',
            prompts: 25,
            selected: false,
        },
        {
            title: 'Standard',
            price: '$10/mo',
            prompts: 50,
            selected: false,
        },
        {
            title: 'Premium',
            price: '$15/mo',
            prompts: 100,
            selected: false,
        },
    ])

    const onPkgClick = (item) => {
        let updatedPricing = pricing.map((pricing) => {
            if (pricing.title === item.title) {
                pricing.selected = !pricing.selected
            } else {
                pricing.selected = false
            }
            return pricing
        })
        setPricing(updatedPricing)
    }
        

    const handleSignUp = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        // see if the user already exists
        const { data, error } = await supabase
            .from('users')
            .select('email')
            .eq('email', email)
        if (error) {
            setError(error.message)
            setLoading(false)
            return
        }
        if (data.length > 0) {
            setError('User already exists')
            setLoading(false)
            return
        }
        // create the user
        const promptsLeft = pricing.filter((item) => item.selected)[0].prompts
        const packageType = pricing.filter((item) => item.selected)[0].title
        console.log(promptsLeft + ' ' + packageType)

        const {_data, _error} = await supabase
            .from('users')
            .insert([
                {
                    email: email,
                    password: password,
                    FullName: fullName,
                    promptsLeft: promptsLeft,
                    packageType: packageType,
                }
            ])
        if (_error) {
            setError(_error.message)
            setLoading(false)
            return
        }

        history.push({
            pathname: '/checkout',
            state: {
                email: email,
                password: password,
                fullName: fullName,
                package: pricing.filter((item) => item.selected)[0].title,
                prompts: pricing.filter((item) => item.selected)[0].prompts,
            }
        })
    }
    return (
        <div>
            <div className="flex flex-col items-start w-screen h-screen bg-blue-primary">
                <div className='flex flex-col items-center justify-center w-full h-full p-12'>
                    <div className="flex flex-col items-center w-full h-1/4">
                        <Navbar marginTop={12} />
                    </div>
                    <div className="flex flex-col items-center w-full mb-10">
                        <h2 className="text-3xl font-sfPro font-normal text-blue-secondary"> SignUp to <span className='font-clashDisplay font-bold'>BrainBox</span></h2>
                    </div>
                    <div className="flex flex-col items-center w-full h-full font-sfPro">
                        <input onChange={fullNameChange} type="text" placeholder="Full Name" className="w-full md:w-1/3 h-16 px-4 mb-4 text-lg placeholder:text-blue-lightPrimary text-blue-lightPrimary bg-[#39036b] border border-blue-lightPrimary rounded-full focus:outline-none focus:border-blue-primary" />
                        <input onChange={emailChange} type="text" placeholder="Email" className="w-full md:w-1/3 h-16 px-4 mb-4 text-lg placeholder:text-blue-lightPrimary text-blue-lightPrimary bg-[#39036b] border border-blue-lightPrimary rounded-full focus:outline-none focus:border-blue-primary" />
                        <input onChange={passwordChange} type="password" placeholder="Password" className="w-full md:w-1/3 h-16 px-4 mb-4 text-lg placeholder:text-blue-lightPrimary text-blue-lightPrimary bg-[#39036b] border border-blue-lightPrimary rounded-full focus:outline-none focus:border-blue-primary" />
                        {pricingCard(pricing, onPkgClick)}
                        {pricing.map((item) => (
                            item.selected ? <p className="text-blue-secondary mt-5 font-bold">Your package : {item.prompts} prompts</p> : null
                        ))}
                        <Button onClick={handleSignUp} text="Signup" 
                        colour="bg-blue-secondary" textColour="text-blue-primary" hoverColour="bg-blue-lightSecondary" link="/login" borderClr="border-blue-lightSecondary" />
                        {error ? <p className="text-red-500 mt-5">{error}</p> : null}
                        {loading ? <p className="text-blue-secondary mt-5">Loading...</p> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SignUp

function pricingCard(price, onClick){
    return(
    <div className='flex flex-row items-center justify-center w-10/12'>
        {price.map((item) => (
            <button onClick={() => onClick(item)} className={`flex flex-col items-center justify-center w-52 p-4 m-4 text-lg font-sfPro font-normal text-blue-primary bg-blue-secondary border-blue-lightPrimary rounded-2xl hover:bg-blue-lightSecondary ${item.selected ? 'border-2 border-blue-primary' : 'border-none'} hover:border-blue-lightSecondary`}>{item.title}<br/>{item.price}<br/>{item.prompts}/month</button>
        ))}
    </div>
    )
}