import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { createClient } from '@supabase/supabase-js'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
const supabaseUrl = 'https://rgyzwbtbfegtsrqtgwzd.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJneXp3YnRiZmVndHNycXRnd3pkIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY3NzUxNTg0MSwiZXhwIjoxOTkzMDkxODQxfQ.Lqn5d5lvq_1w01hBKy4Gt_TMYFF4VwTp7o2mDcUbutc'
const supabase = createClient(supabaseUrl, supabaseKey)

function Payment() {
    const location = useLocation()
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [fullName, setFullName] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState('')
    const [price, setPrice] = useState(0)
    const history = useHistory()

    const setUserPrice = (price) => {
        if(location.state.package === 'Basic') {
            setPrice(5)
        } else if (location.state.package === 'Standard') {
            setPrice(10)
        } else if (location.state.package === 'Premium') {
            setPrice(15)
        }
    }

    useEffect(() => {
        setUserPrice()

    }, [])
    return (
        <div>
            <div className="flex flex-col items-start w-screen min-h-screen bg-blue-primary">
                <div className='flex flex-col items-center justify-center w-full h-full p-12'>
                    <div className="flex flex-col items-center w-full h-1/4">
                        <Navbar marginTop={12} />
                    </div>
                    <div className="flex flex-col items-center w-full mb-10 mt-12">
                        <h2 className="text-3xl font-sfPro font-normal text-blue-secondary"> SignUp to <span className='font-clashDisplay font-bold'>BrainBox</span></h2>
                    </div>
                    <div className="flex flex-col items-center w-full mt-36">
                        <p className="text-2xl font-sfPro font-normal text-blue-secondary mb-5">You have to pay ${price}</p>
                        <PayPalScriptProvider options={{ "client-id": "AYSQyRUwHY-USMv_bDPcvivKGdk6aqEaHq2MPRzMvYMQRP-qWbVDEP7E_mCRkhdvXuoPxKznKntffo13" }}>
                            <PayPalButtons 
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: price,
                                            },
                                        },
                                    ],
                                });
                            }
                            }
                            onApprove={(data, actions) => {
                                return actions.order.capture().then(function(details) {
                                    alert('Transaction completed by ' + details.payer.name.given_name);
                                    history.push({
                                        pathname: '/chat',
                                        state: {
                                            package: location.state.package,
                                            email: location.state.email,
                                            password: location.state.password, }
                                        })
                                    })
                                }}
                            />
                        </PayPalScriptProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Payment