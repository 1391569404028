import React from 'react'
import Navbar from '../components/Navbar'
import Heading from '../components/Heading'
import Button from '../components/Button'
import { Link, useHistory } from 'react-router-dom'
import { createClient } from '@supabase/supabase-js'
const supabaseUrl = 'https://rgyzwbtbfegtsrqtgwzd.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJneXp3YnRiZmVndHNycXRnd3pkIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY3NzUxNTg0MSwiZXhwIjoxOTkzMDkxODQxfQ.Lqn5d5lvq_1w01hBKy4Gt_TMYFF4VwTp7o2mDcUbutc'
const supabase = createClient(supabaseUrl, supabaseKey)

function Login() {
    const history = useHistory()
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleLogin = async (e) => {
        console.log(email, password)
        e.preventDefault()
        setLoading(true)
        const {data , error} = await supabase.from('users').select('*').eq('email', email).eq('password', password)
        if (error) {
            setError(error.message)
            return
        }
        setLoading(false)
        history.push({
            pathname: '/chat',
            state: {email: email, password: password}
        })
    }
    return (
        <div>
            <div className="flex flex-col items-start w-screen h-screen bg-blue-primary">
                <div className='flex flex-col items-center justify-center w-full h-full p-5 md:p-12'>
                    <div className="flex flex-col items-center w-full h-1/2">
                        <Navbar marginTop={12}/>
                    </div>
                        <div className="flex flex-col items-center w-full mb-10">
                        <h2 className="text-3xl font-sfPro font-normal text-blue-secondary text-center"> Login to <span className='font-clashDisplay font-bold'>BrainBox</span></h2>
                    </div>
                    <div className="flex flex-col items-center w-full h-full font-sfPro">
                        <input onChange={handleEmail} type="text" placeholder="Email" className="w-full md:w-1/3 h-16 px-4 mb-4 text-lg placeholder:text-blue-lightPrimary text-blue-lightPrimary bg-[#39036b] border border-blue-lightPrimary rounded-full focus:outline-none focus:border-blue-primary" />
                        <input onChange={handlePassword} type="password" placeholder="Password" className="w-full md:w-1/3 h-16 px-4 mb-4 text-lg placeholder:text-blue-lightPrimary text-blue-lightPrimary bg-[#39036b] border border-blue-lightPrimary rounded-full focus:outline-none focus:border-blue-primary" />
                        <Link onClick={handleLogin} className="mb-4 bg-blue-secondary mt-3 px-24 py-4 text-lg rounded-full font-normal text-blue-primary font-sfPro hover:bg-blue-lightSecondary">Login</Link>
                        {error ? <p className="text-red-500">{error}</p> : null}
                        {loading ? <p className="text-blue-lightPrimary mt-5">Loading...</p> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login