import Button from '../components/Button';
import { useEffect, useState } from 'react';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { IoCloseSharp } from 'react-icons/io5';
import { Configuration, OpenAIApi } from 'openai';
import { createClient } from '@supabase/supabase-js'
import { useLocation } from 'react-router-dom';
import '../App.css'


function MainPage() {
    const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    const location = useLocation();
    const openai = new OpenAIApi(configuration);
    const [usrInput, setUsrInput] = useState('');
    const [prompt, setPrompt] = useState();
    const [packageType, setPackageType] = useState();
    const [usrName, setUserName] = useState();
    const [email, setEmail] = useState(
        location.state ? location.state.email : ''
    );
    const [password, setPassword] = useState(
        location.state ? location.state.password : ''
    );
    const supabaseUrl = 'https://rgyzwbtbfegtsrqtgwzd.supabase.co'
    const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJneXp3YnRiZmVndHNycXRnd3pkIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY3NzUxNTg0MSwiZXhwIjoxOTkzMDkxODQxfQ.Lqn5d5lvq_1w01hBKy4Gt_TMYFF4VwTp7o2mDcUbutc'
    const supabase = createClient(supabaseUrl, supabaseKey)
    const [userInputList, setUserInputList] = useState([]);
    const [loadingState, setLoadingState] = useState(false);
    const [mobileMenuState, setMobileMenuState] = useState('hidden z-0 absolute bg-[#2E0158] flex-col items-center justify-center w-screen gap-y-5 h-screen overflow-hidden');

    const getUsersOldPrompts = async () => {
        setLoadingState(true);
        const { data, error } = await supabase
            .from('users')
            .select('*').eq('email', email)
        if (error) {
            console.log(error)
        }
        const data_ = [{}]
        for (let i = 0; i < data[0].data.length; i++) {
            const element = data[0].data[i];
            if (element.input || element.response) {
                data_.push({
                    input: element.input,
                    response: element.response,
                })
            }
        }
        setLoadingState(false);
        setUserInputList(data_)
    }

    async function getUsrData (){
        const { data, error } = await supabase
            .from('users')
            .select('*').eq('email', email)
        if (error) {
            console.log(error)
        }
        console.log(data)
        setPrompt(data[0].promptsLeft)
        setPackageType(data[0].packageType)
        setUserName(data[0].FullName)
    }

    useEffect(() => {
        console.log(email)
        getUsrData()
        updatePromptsAmount();
    }, [prompt])

    const mobileMenu = () => {
        if (mobileMenuState === 'hidden z-0 absolute bg-[#2E0158] flex-col items-center justify-center w-screen gap-y-5 h-screen overflow-hidden') {
            setMobileMenuState('flex z-0 absolute bg-[#2E0158] flex-col items-center justify-center w-screen gap-y-5 h-screen overflow-hidden');
        } else {
            setMobileMenuState('hidden z-0 absolute bg-[#2E0158] flex-col items-center justify-center w-screen gap-y-5 h-screen overflow-hidden');
        }
    }

    const clearChat = async () => {
        // clear local array and database
        setUserInputList([]);
        const { data, error } = await supabase
            .from('users')
            .update({ data: [] }).eq('email', email)
        if (error) {
            console.log(error)
        }
    }

    const updatePromptsAmount = async () => {
        // update prompts amount in database
        const { data, error } = await supabase
            .from('users')
            .update({ promptsLeft: prompt }).eq('email', email)
        if (error) {
            console.log(error)
        }
    }

    const onClick = async () => {
        setPrompt(prompt - 1);
        setLoadingState(true);
        setUserInputList([
            ...userInputList,
            {
                input: usrInput,
                response: '',
            },
        ]);
        const response = await openai.createCompletion({
            model: "text-davinci-003",
            prompt: `Give me answer as ChatGPT ${usrInput}`,
            max_tokens: 1000,
            temperature: 0.9,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
        });
        const result = response.data.choices[0].text;
        setUserInputList([
            ...userInputList,
            {
                input: usrInput,
                response: result,
            },
        ]);
        setLoadingState(false);
        const { data, error } = await supabase
            .from('users')
            .update({ data: userInputList })
            .eq('email', email)
        if (error) {
            console.log(error)
        }
        setUsrInput('');
    };
    return (
        <div className="flex flex-col md:flex-row items-start min-w-screen bg-blue-primary">
            <aside className="md:flex bg-[#2E0158] flex-col items-center justify-center lg:w-2/12 md:w-4/12 h-screen hidden p-3">
                <div className="flex flex-col items-start justify-start w-full h-full">
                    <h2 className="text-3xl px-2 mt-3 font-clashDisplay font-bold text-blue-secondary">BrainBox</h2>
                    <h1 className="text-md px-2 mt-10 font-sfPro text-blue-secondary">Welcome <br/> <span className='text-lg font-bold'> {usrName} </span> </h1>
                    <h1 className="text-md px-2 mt-5 font-sfPro text-blue-secondary"> Package : {packageType}</h1>
                    <h1 className="text-md px-2 mt-5 font-sfPro text-blue-secondary"> Prompts Left (this month) : {prompt}</h1>
                    <div className='flex flex-col items-center justify-center w-full h-full mt-6'>
                        <ul className="flex font-sfPro text-xl flex-col items-start justify-start w-full h-full text-blue-lightPrimary">
                        </ul>
                    </div>
                    <div className="flex flex-col mb-3 items-center justify-center w-full h-2/6">
                        <Button onClick={clearChat} text="Clear Chat" colour="bg-[#ff6363]" textColour="text-white" borderClr="border-blue-lightSecondary" />
                        <Button text="Load Chats" colour="bg-blue-secondary" textColour="text-blue-primary" hoverColour="bg-blue-lightSecondary" onClick={getUsersOldPrompts} borderClr="border-blue-lightSecondary" />
                        <Button text="Logout" colour="bg-blue-secondary" textColour="text-blue-primary" hoverColour="bg-blue-lightSecondary" link="/login" borderClr="border-blue-lightSecondary" />
                    </div>
                </div>
            </aside>
            {/* icons for menu */}
            <div onClick={mobileMenu} className="flex flex-col items-start w-full h-full md:hidden sticky top-0 bg-blue-primary">
                <div className="flex flex-row items-center w-full h-1/6">
                    <div className="flex flex-row items-start w-full h-full">
                        <HiOutlineMenuAlt2 className="text-blue-secondary text-4xl mt-4 ml-4 cursor-pointer" />
                        <h2 className="text-3xl mt-4 w-full text-center font-clashDisplay font-bold text-blue-secondary">BrainBox</h2>
                    </div>
                </div>
            </div>
            {/* mobile friendly sidebar */}
            <div className={mobileMenuState}>
                <div className='flex flex-col min-h-screen w-full items-center justify-center'>
                    {/* MENU CLOSE ICON */}
                    <IoCloseSharp onClick={mobileMenu} className="text-blue-secondary text-4xl mt-4 cursor-pointer mb-8" />
                    <div className="flex flex-col items-center justify-center">
                        <a onClick={clearChat} className='font-sfPro w-full text-xl font-bold mb-4 text-center text-blue-secondary cursor-pointer'>Clear Chat</a>
                        <a onClick={getUsersOldPrompts} className='font-sfPro w-full text-xl mb-4 text-center font-bold text-blue-secondary cursor-pointer'>Load Chats</a>
                        <a href="/login" className='font-sfPro text-center w-full text-xl font-bold mb-4 text-blue-secondary'>Logout</a>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full h-screen p-4 mt-8 md:mt-0">
                <div className="flex flex-col w-full">
                    <h1 className="text-3xl font-sfPro font-bold text-blue-secondary mt-2">Chat</h1>
                </div>
                {/* Show all the chats */}
                <div className="flex font-sfPro text-xl mt-8 text-blue-lightPrimary flex-col items-start justify-start w-full h-full overflow-auto">
                    {userInputList?.map((userInputVar) => (
                        <>
                            <li className="flex flex-row items-start justify-start w-full">You: {userInputVar.input}</li>
                            <li className="flex text-blue-lightSecondary flex-row items-start justify-start w-full mb-10">Bot: {userInputVar.response}</li>
                        </>
                    ))}
                    {loadingState ? loading() : null}
                </div>
                {/* display input box at bottom of the screen */}
                <div className="flex flex-col items-baseline justify-end w-full mt-4 sticky bottom-0">
                    <div className="flex flex-row items-center justify-between w-full">
                        <textarea rows={1} onChange={(e) => { setUsrInput(e.target.value) }} placeholder='Write yor message' type="text" className="w-full py-4 border border-blue-lightPrimary font-sfPro placeholder:text-blue-lightPrimary text-blue-lightPrimary placeholder:px-2 rounded-full bg-[#39036b]" />
                        {prompt > 0 ? <button onClick={onClick} className="flex flex-row font-sfPro font-semibold items-center justify-center w-2/6 md:w-1/6 text-lg py-4 ml-2 text-blue-primary bg-blue-secondary rounded-full">
                            Send
                        </button> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MainPage;


function loading() {
    return (
        <>
            <div className="w-36 text-sm text-blue-lightPrimary">
                <h2>Fetching Result</h2>
            </div>
        </>
    )
}